@use './variables' as *;

.p-tabview-nav-link {
  font-size: 14px;
}

.p-popover-content {
  background-color: $body-bg-color;
}

.p-button {

  .p-badge {
    border-radius: 0 4px 4px 4px;
    color: $black;
  }

  &:enabled {

    &:hover {
      background: var(--primary-color);
    }

    &:focus {
      background: var(--primary-color);
    }
  }

  &.p-button-outlined {
    background: rgba(63, 81, 181, 0);
    border: solid 1px;
    border-color: var(--primary-color);
    color: var(--primary-color);

    &.inverted {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }
  }

  &.p-button-text:enabled:focus,
  &.p-button-outlined:enabled:hover {
    background: var(--primary-color);
    color: var(--secondary-text-color);
  }
}

.p-badge {

  &.p-badge-danger {
    min-width: 15px;
    height: 15px;
    width: 15px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 4px 4px 4px;
    padding-top: 2px;
    background-color: var(--red-color);
    color: $white;
  }
}

.p-overlay-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-component {
  font-family: $main-font-family;
  font-size: 14px;
  font-weight: $font-weight-light;

  &.p-toast-top-right {
    z-index: 9999 !important;
  }

  &.p-button {
    font-size: 14px;
    padding: 10px 20px;
    justify-content: center;
    border-radius: var(--border-radius);

    &.p-button-danger {
      background-color: var(--red-color);
    }

    &:disabled {
      background-color: var(--secondary-text-color);
      color: $white;
      opacity: 1;

      &.p-button-outlined {
        color: $white;
        border-color: var(--secondary-text-color);
      }
    }
  }

  &.p-avatar {
    background-color: $white;
    width: 30px;
    height: 30px;

    .pi-user {
      &:before {
        color: var(--primary-color);
      }
    }
  }
}

.pi-sort-alt,
.pi-sort-amount-up-alt,
.pi-sort-amount-down {
  &:before {
    font-size: 10px;
    font-weight: bold;
    display: flex;
  }
}

.p-datepicker-calendar,
.p-datepicker-header,
.p-datepicker-select-year,
.p-datepicker-day,
.p-checkbox-box,
.p-multiselect-list,
.p-multiselect-label,
.p-select-option,
.p-select-list,
.p-multiselect-option,
.p-multiselect,
.p-dropdown,
.p-datepicker-prev-button,
.p-datepicker-next-button
select {
  border-color: var(--primary-color);
  background-color: white;
  color: var(--primary-color);

  &:not(.p-disabled) {
    &:hover {
      border-color: var(--primary-color);
    }
  }
}

.p-checkbox-checked.p-highlight .p-checkbox-box {
  background-color: var(--primary-color);
}

.p-select-option:not(.p-disabled):hover, 
.p-multiselect-option:not(.p-multiselect-option-selected):not(.p-disabled).p-focus {
  color: #000000de;
  background: rgba(0, 0, 0, 0.05);
}
.p-multiselect-option


.password,
.p-password {
  width: 100%;

  input {
    width: 100%;
  }

  .pi-eye {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
  }
}

.p-inputtext {
  border-color: var(--primary-color);
  padding: 10px;
  border-radius: var(--border-radius);

  &:enabled {
    border-color: var(--primary-color);

    &:hover {
      border-color: var(--primary-color);
    }
  }

  .p-datatable-flex-scrollable .p-datatable-wrapper {
    padding: 0 10px 10px;
    background: white;
    border-radius: var(--border-radius);
  }

  // S.T. tmp for ER-352
  // .p-datatable-tbody .p-component.p-button .p-button-label {
  // 	display: none;
  // }
}

.p-inline-message.p-inline-message-error {
  background: transparent;
  border-width: 0;
  padding-left: 0;

  .p-inline-message-icon {
    display: none;
  }

  .p-inline-message-text {
    font-size: 11px;
    color: var(--red-color);
  }
}

.p-datatable,
.p-treetable {
  .p-sortable-column.p-highlight,
  .p-sortable-column.p-highlight:hover,
  .p-sortable-column:not(.p-highlight):hover {
    background: transparent;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: var(--primary-color);
    }
  }

  padding: 10px;
  background: white;
  border-radius: var(--border-radius);

  .p-datatable-thead > tr > th,
  .p-treetable-thead > tr > th {
    font-size: 12px;
    line-height: 22px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    white-space: nowrap;
    border-bottom: none;
    padding: 14px 0 17px;
    text-align: center;
    justify-content: center;
    border-color: #f5f7fb;
    border-width: 0 1px 0 0;
    color: var(--primary-color);
    background-color: white;
  }

  .p-datatable-tbody > tr,
  .p-treetable-tbody > tr {
    height: 60px;
    align-items: center;
    color: $black;
    background-color: $white;

    &:nth-child(2n + 1) {
      background-color: var(--pink-light-color);

      > td {
        border-color: $white;
      }
    }

    > td {
      border-color: #f5f7fb;
      border-width: 0 1px 0 0;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      height: 60px;
      padding: 0 10px;

      &.organization-columns-label {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
      }

      .p-treetable-toggler {
        color: var(--primary-color);
      }
    }
  }
}

.p-dialog {
  // prevent dialog above footer
  max-height: calc(90% - 30px);
  bottom: 30px;

  .p-dialog-header {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: relative;
    margin-right: 2.5rem;

    .p-dialog-title {
      text-align: left;
      width: 100%;
      font-size: 20px;
      font-weight: $font-weight-semi-bold;
      margin-right: 25px;
      display: inline-block;
    }

    .p-dialog-header-icons {
      display: flex;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      right: -1.9rem;
      justify-content: flex-end;
    }

    .p-dialog-header-icon {
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      color: var(--primary-color);
    }
  }

  .p-dialog-content {
    border-radius: var(--border-radius);
    color: var(--primary-color);
    background-color: white;
  }

  .p-dialog-footer {
    button {
      &:last-child {
        margin: 0;
      }
    }
  }
}
select {
  color: var(--primary-color);
}
input {
  border-radius: var(--border-radius);
  height: 40px;
  font-size: 14px;
  border: 1px solid var(--primary-color);
  outline-color: var(--primary-color);
  padding-left: 15px;
  background-color: white;
  color: var(--primary-color);

  &::placeholder {
    font-style: italic;
    font-weight: $font-weight-light;
  }

  &.p-inputtext.ng-dirty.ng-invalid {
    border-color: var(--red-color);
  }
}

.password .pi-eye,
.p-password .pi-eye,
.password .p-input-icon-right > i:last-of-type {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  margin: 0;
}

//global

.main-container {
  max-width: var(--main-width-container);
  margin: 0 auto;
  position: relative;

  &.admin-users .p-datatable {
    padding-bottom: 100px;
  }
}

.p-carousel-more {
  width: 20px;
  font-size: 20px;
  margin: 0 auto;
}

.p-carousel {

  .p-carousel-indicators {
    .p-carousel-indicator {
      &.p-highlight {
        button {
          background: var(--secondary-color);
        }
      }
    }
  }
}

.p-datatable .p-datatable-loading-overlay {
  background-color: var(--pink-light-color);
  color: var(--secondary-color);
  // no overlay on top of table header
  top: 56px;
  height: calc(100% - 56px);
}

.p-overlaypanel-content {
	min-width: 180px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  max-width: 270px;
  overflow: hidden;
}

.p-tabpanels {
  background: $white;
  color: var(--primary-color);
}

.login-container .p-select-list-container .p-select-list .p-select-option {
  display: list-item;
}


// .orders-table-container {
//   .order-cell_status {
//     max-width: 150px;
//   }
// }
.orders-table-container .p-datatable-table.p-datatable-scrollable-table, .orders-logs-container table {
    table-layout: fixed;
}
