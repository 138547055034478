/* You can add global styles to this file, and also import other style files */
@use './style/variables' as *;
@use './style/reset';
@use './style/common';
@use './style/theme-override';
@use './style/details-page';
@use './style/layout';
@use "primeicons/primeicons.css";



body {
  font-family: $main-font-family;
  color: var(--black-primary);
  background-color: #f5f7fb;
  touch-action: manipulation; // prevent zoom on double tap
}

a,
.p-button.p-button-text {
  color: var(--primary-color);
}

.p-button {
  background-color: var(--primary-color);
  border: var(--primary-color);
  color: $white;
}

button {
  &.link {
    color: var(--primary-color);
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
  }
}

.error {
  color: rgb(201, 22, 22);
}

.p-dialog:not(.p-dialog-fullscreen) {
  max-width: 80vw;
}
